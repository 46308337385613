.cotg-insights-insight-actions {
    display: flex;
    position: relative;
}

.cotg-insights-insight-actions input.clipboard {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
}

.cotg-insights-insight-actions .button {
    cursor: pointer;
}

.cotg-insights-insight-actions .button:not(.active):not(:active) {
    filter: brightness(0) invert(1);
}

.cotg-insights-insight-actions .button:not(:last-child) {
    margin-right: 20px;
}

.cotg-insights-insight-actions .button.flip {
    transform: rotate(180deg)
}

.cotg-insights-insight-actions .button.grow {
    margin-left: auto;
}

@keyframes cotg-insights-insight-actions-animation {
    10% {
        transform: scale(0.8);
        opacity: 0.4
    }
    40% {
        transform: scale(1.1) rotate(-10deg)
    }
    900% {
        transform: scale(1) rotate(5deg)
    }
}

.cotg-insights-insight-actions .button.animate img {
    animation-name: cotg-insights-insight-actions-animation;
    animation-duration: 0.8s;
}
.cotg-app-bar {
  width: calc(100% - 40px);
  height: 58px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.cotg-app-bar>* {
  flex-shrink: 0;
}

.cotg-app-bar .business {
  display: flex;
  align-items: center;
  max-width: calc(100% - 100px)
}

.cotg-device-desktop .cotg-app-bar .business.multiple:hover, .cotg-app-bar .business.multiple:active {
  filter: brightness(80%);
  cursor: pointer;
}

.cotg-app-bar .business .logo {
  display: grid;
  margin-right: 10px;
}

.cotg-app-bar .business .logo img {
  max-width: 120px;
  max-height: 31px;
  filter: brightness(0) invert(1);
}

.cotg-app-bar .business .name {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.57px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cotg-app-bar .controls {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.cotg-app-bar .controls>* {
  flex-shrink: 0;
}

.cotg-app-bar .controls> :first-child {
  margin-left: auto;
}

.cotg-app-bar .controls>.control {
  user-select: none;
  cursor: pointer;
}

.cotg-app-bar .controls>.control[disabled] {
  filter: grayscale(1) brightness(115%);
}

.cotg-app-bar .controls>.control:active, .cotg-device-desktop .cotg-app-bar .controls>.control:hover {
  filter: brightness(80%);
}

.cotg-app-bar .controls>.control[disabled]:active, .cotg-device-desktop .cotg-app-bar .controls>.control[disabled]:hover {
  filter: grayscale(75%) brightness(80%);
}

.cotg-app-bar .controls .divider {
  width: 2px;
  height: 21px;
  background: #333b4a;
  margin: 0 9px;
}

.cotg-app-bar .controls .live {
  font-weight: bold;
  letter-spacing: 0.31px;
  color: #02b9e5;
  text-transform: uppercase;
}

.cotg-app-bar .controls .live:not([disabled]) {
  text-shadow: 0 0 4px rgba(94, 209, 237, 0.3);
}

.cotg-app-bar .controls .live:not([disabled]) img {
  filter: drop-shadow( 0px 1px 2px #09b6e280);
}

.cotg-app-bar .controls .logout {
  margin-top: 2px;
}
.cotg-insights-coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
}

.cotg-insights-coming-soon .subtitle {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.64px;
    margin-top: 10px;
} 
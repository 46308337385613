#cotg-comodashview {
   position: fixed;
   z-index: 2000;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: flex;
   pointer-events: none;
}

#cotg-comodashview:empty {
   display: none;
}
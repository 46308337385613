.cotg-navigation {
    width: 100%;
    display: flex;
    justify-content: center;
}

.cotg-navigation .tab {
    color: rgba(255, 255, 255, 0.7);
    flex: 1;
    height: 48px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.57px;
    user-select: none;
    cursor: pointer;
}

.cotg-navigation .tab .text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cotg-navigation .tab.selected .text {
    color: white;
}

.cotg-navigation .tab .text span {
    position: relative;
}

.cotg-navigation .tab .text span .badge {
    position: absolute;
    right: -33px;
    top: 2px;
    width: 26px;
    height: 18px;
    border-radius: 9px;
    box-shadow: 0 0 10px 0 rgba(149, 223, 241, 0.4);
    background-image: linear-gradient(112deg, #14bde4 10%, #09b6e2 44%, #0288bb 92%);
    
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
}

.cotg-navigation .tab .indicator {
    background: #343e4e;
    width: 100%;
    height: 2px;
}

.cotg-navigation .tab.selected .indicator {
    height: 3px;
    background: linear-gradient( to right, #14bde4, #09b6e2, #0288bb);
    box-shadow: 0 0 10px 0 rgba(149, 223, 241, 0.4);
}

.cotg-navigation .tab:active, .cotg-device-desktop .cotg-navigation .tab:hover {
    filter: brightness(120%);
}
.cotg-dashboard-groupby-kpi {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(37, 40, 44, 0.5);
    background-image: linear-gradient(130deg, #333d4f66, #333d4e66 32%, #3f4a5c66 64%, #4d586a66);
    padding: 14px;
}

.cotg-dashboard-groupby-kpi .title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.61px;
    text-transform: uppercase;
    /* margin: 14px; */
}

.cotg-dashboard-groupby-kpi .data {
    height: 250px;
    margin: auto;
}

.cotg-dashboard-groupby-kpi .data text {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 10px !important;
    font-weight: 600;
    letter-spacing: normal;
}
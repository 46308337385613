.cotg-select {
    width: 100%;
    display: flex;
    height: 45px;
    border-radius: 22.5px;
    box-shadow: 0 2px 6px 0 #25282c66;
    background-image: linear-gradient(98deg, #333d4f66, #333d4e66 32%, #3f4a5c66 64%, #4d586a66);
}

.cotg-select .option {
    flex: 1;
    font-size: 14px;
    color: #d9e0e8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22.5px;
    cursor: pointer;
    user-select: none;
}

.cotg-select .option.selected {
    width: 80px;
    box-shadow: 0 0 6px 0 rgba(149, 223, 241, 0.3);
    background-image: linear-gradient(107deg, #14bde4, #09b6e2 42%, #0288bb);
    margin: 3px;
    font-weight: 600;
    color: #ffffff;
}

.cotg-select .option:active,
.cotg-device-desktop .cotg-select .option:hover {
    filter: brightness(110%);
}

.cotg-select[disabled] .option.selected {
    filter: grayscale(1) brightness(115%);
    background: #09b6e2;
    box-shadow: none;
}
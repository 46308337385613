html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  /* position: fixed; */
  background: #242b38;
}

.cotg-app {
  min-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;

  font-family: 'Open Sans', sans-serif;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.cotg-app > *:not(.content-scroll) {
  flex-shrink: 0;
}

.cotg-app>.content-scroll {
  flex-grow: 1;
  overflow-y: overlay;
  -webkit-overflow-scrolling:touch;
  width: 100%;
}

.cotg-app>.cotg-navigation,
.cotg-app>.content-scroll>.content {
  max-width: 1200px;
  margin: auto;
}
.cotg-dashboard {
    padding: 25px 20px;
}

.cotg-dashboard>* {
    margin-bottom: 15px;
}

.cotg-dashboard>.kpis {
    display: grid;
    grid-template-columns: calc(50% - 7px) calc(50% - 7px);
    grid-gap: 14px;
}

.cotg-dashboard>.kpis .cotg-dashboard-kpi.span-column {
    grid-column: span 2;
}

.cotg-dashboard>.kpis .cotg-dashboard-kpi.span-row {
    grid-row: span 2;
}

.cotg-dashboard>.kpis .cotg-dashboard-kpi.strech {
    margin: auto -15px;
    grid-column: span 2;
}
.cotg-dashboard-amount-kpi {
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(37, 40, 44, 0.5);
    background-image: linear-gradient(130deg, #333d4f66, #333d4e66 32%, #3f4a5c66 64%, #4d586a66);
    height: 135px;
    display: grid;
    grid-template-rows: 1.2fr 2fr;
}

.cotg-dashboard-amount-kpi>* {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cotg-dashboard-amount-kpi .title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.61px;
    text-transform: uppercase;
}

.cotg-dashboard-amount-kpi .data {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.cotg-dashboard-amount-kpi .data .value {
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.19px;
}

.cotg-dashboard-amount-kpi .data .trend {
    margin-bottom: auto;
}

.cotg-dashboard-amount-kpi .data .trend>div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
}

.cotg-dashboard-amount-kpi .data .trend img {
    width: 17px;
    margin-right: -2px;
}

.cotg-dashboard-amount-kpi .data .trend.up {
    color: #18bd96;
}

.cotg-dashboard-amount-kpi .data .trend.up img {
    filter: invert(55%) sepia(74%) saturate(460%) hue-rotate(116deg) brightness(94%) contrast(91%);
}

.cotg-dashboard-amount-kpi .data .trend.down {
    color: #da4453;
}

.cotg-dashboard-amount-kpi .data .trend.down img {
    filter: invert(36%) sepia(33%) saturate(5105%) hue-rotate(332deg) brightness(93%) contrast(83%);
}

.cotg-dashboard-amount-kpi.span {
    height: 60px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.cotg-dashboard-amount-kpi.span>* {
    margin-left: 12px;
}

.cotg-dashboard-amount-kpi.span .title {
    margin-right: auto;
}

.cotg-dashboard-amount-kpi.span .data {
    display: flex;
}

.cotg-dashboard-amount-kpi.span .data .value {
    font-size: 22px;
}

.cotg-dashboard-amount-kpi.span .data .trend {
    position: absolute;
    right: 10px;
}
.cotg-insights-insight {
    background-image: linear-gradient(130deg, #02b9e5, #6c46cb);
    border-radius: 6px;
    box-shadow: 0 2px 0 0 #25282c;
    min-height: 287px;
    display: flex;
}

.cotg-device-desktop .cotg-insights-insight {
    height: 250px;
}

.cotg-insights-insight .card {
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(133deg, #2a313e, #2a313e 32%, #2f3643 64%, #313845);
    display: flex;
    flex-direction: column;
    padding: 22px;
    padding-left: 17px;
}

.cotg-insights-insight.highlight {
    padding: 2px;
}

.cotg-insights-insight.highlight .card {
    padding: 20px;
    padding-left: 15px;
}

.cotg-insights-insight .card .notification {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.64;
    color: #ffffff7F;
    position: relative;
}

.cotg-insights-insight .card .notification .created {
    flex: 1;
}

.cotg-insights-insight .card .notification .new {
    position: absolute;
    right: -11px;
    top: -8px;
}

.cotg-insights-insight .card .cotg-insights-insight-header {
    margin-top: 13px;
}

.cotg-insights-insight .card .cotg-insights-insight-body {
    margin-top: 18px;
}

.cotg-insights-insight .card .cotg-insights-insight-actions {
    margin-top: 18px;
}
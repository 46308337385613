.cotg-insights-insight-header {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2.42px;
    color: #ffffff;
    text-transform: uppercase;
    align-items: center;
}

.cotg-insights-insight-header img {
    margin-right: 10px;
}
.cotg-user-login {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow-y: overlay;
    width: 100%;
    margin: auto;
    padding: 50px 0;
}

.cotg-user-login>* {
    width: calc(100% - 60px);
    max-width: 360px;
}

/* Logo */

.cotg-user-login .logo img {
    width: 130px;
}

/* Title */

.cotg-user-login .title {
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 35px;
    font-weight: 600;
}

/* Greeting/Error */

.cotg-user-login .greeting {
    margin-top: 15px;
    font-weight: 600;
    line-height: 1.75;
}

/* Inputs */

.cotg-user-login .inputs {
    margin-top: 35px;
}

.cotg-user-login .inputs .input {
    position: relative;
}

.cotg-user-login .inputs .input:not(:last-child) {
    margin-bottom: 15px;
}

.cotg-user-login .inputs img {
    float: left;
    position: absolute;
    margin: auto 0;
    top: -2px;
    left: 15px;
    bottom: 0;
    right: 0;
}

.cotg-user-login .inputs input, .cotg-user-login .inputs input:-webkit-autofill, .cotg-user-login .inputs input:-webkit-autofill:hover, .cotg-user-login .inputs input:-webkit-autofill:focus, .cotg-user-login .inputs input:-webkit-autofill:active {
    width: calc(100% - 75px);
    height: 47px;
    background: #242b38;
    -webkit-box-shadow: 0 0 0 30px #242b38 inset !important;
    -webkit-text-fill-color: white !important;
    border-radius: 5px;
    border: solid 1px #475266BF;
    font-size: 12px;
    color: white;
    padding-left: 45px;
    padding-right: 30px;
    outline: none;
}

.cotg-user-login .inputs input::placeholder {
    color: #475266;
    -webkit-text-fill-color: #475266 !important;
}

.cotg-user-login .inputs input:focus, .cotg-user-login .inputs input:-webkit-autofill:focus {
    border: solid 1px #02b9e5;
}

.cotg-user-login .inputs input:focus+img, .cotg-user-login .inputs input:-webkit-autofill:focus+img {
    filter: brightness(0) invert(1);
}

.cotg-user-login.error .inputs input, .cotg-user-login.error .inputs input:-webkit-autofill, .cotg-user-login.error .inputs input:-webkit-autofill:hover, .cotg-user-login.error .inputs input:-webkit-autofill:focus, .cotg-user-login.error .inputs input:-webkit-autofill:active {
    border: solid 1px #da4453;
}

/* Submit */

.cotg-user-login .submit {
    margin-top: 60px;
}

.cotg-user-login .submit button {
    border: none;
    outline: none;
    color: white;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(2, 185, 229, 0.3), 0 0 4px 0 rgba(2, 185, 229, 0.35), 0 2px 5px 0 rgba(51, 61, 79, 0.3);
    background-image: linear-gradient(96deg, #14bde4, #09b6e2 42%, #0288bb);
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: -0.02px;
    font-weight: 600;
    cursor: pointer;
}

.cotg-user-login .submit button[disabled] {
    box-shadow: 0 2px 5px 0 rgba(51, 61, 79, 0.3);
    background-image: linear-gradient(99deg, #333d4f, #333d4e 32%, #3f4a5c 64%, #4d586a);
    color: #b7bdd399;
}

.cotg-user-login .submit button:not([disabled]):active, .cotg-device-desktop .cotg-user-login .submit button:not([disabled]):hover {
    border: solid 1.5px #00b0ff;
}

.cotg-user-login .submit>.hint {
    margin-top: 15px;
    font-size: 13px;
    color: #475266;
    cursor: pointer;
}

.cotg-user-login .submit>.hint>.bold {
    font-weight: bold;
    display: inline;
}

.error-Message {
    color: #da4453;
}
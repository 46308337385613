.cotg-transition:not(.show) {
    visibility: hidden;
    position: fixed !important;
}

.cotg-transition.show {
    visibility: visible;
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
}

.cotg-transition.fade:not(.show) {
    opacity: 0;
}

.cotg-transition.fade {
    opacity: 1;
}

.cotg-transition.scale:not(.show) {
    transform: scale(0.6);
}

.cotg-transition.scale {
    transform: scale(1);
}
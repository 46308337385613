.cotg-insights-insight-body {
    flex: 1;
    margin-top: 18px;
}

.cotg-insights-insight-body .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.44;
}

.cotg-insights-insight-body .title mark {
    background: none;
    color: white;
}

.cotg-insights-insight-body .description {
    margin-top: 2px;
    font-size: 14px;
    line-height: 1.64;
}

.cotg-insights-insight-body .description mark {
    font-weight: bold;
    color: #02b9e5;
    background: none;
}
.cotg-dashboard-overtime-kpi {
    text-align: center;
    height: 220px;
}

.cotg-dashboard-overtime-kpi .legends {
    margin-top: -28px;
    display: flex;
    justify-content: center;
}

.cotg-dashboard-overtime-kpi .legends>*:not(:last-child) {
    margin-right: 10px;
}

.cotg-dashboard-overtime-kpi .legends .legend {
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 1.21px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.cotg-dashboard-overtime-kpi .legends .legend .bullet {
    margin-right: 5px;
    height: 13px;
    width: 13px;
    border-radius: 3px;
}

.cotg-dashboard-overtime-kpi .legends .legend.i0 .bullet {
    border: solid 1px #14bde4;
    background-image: linear-gradient(to top, rgba(51, 59, 74, 0) 0%, rgba(40, 87, 108, 0.15) 9%, rgba(36, 97, 120, 0.3) 18%, #02b9e5);
}

.cotg-dashboard-overtime-kpi .legends .legend.i1 .bullet {
    border: solid 1px #651fff;
    background-image: linear-gradient(to top, rgba(51, 59, 74, 0) 0%, rgba(40, 87, 108, 0.15) 9%, rgba(36, 97, 120, 0.3) 18%, #651fff);
}

.cotg-dashboard-overtime-kpi .values-layer {
    border-radius: 4px;
    border: solid 1px #02b9e5;
    /* background-color: rgba(51, 59, 74, 0.65); */
    background-color: #2e3644;
    width: fit-content;
    padding: 6px 7px;
    margin: 0 auto;
}

.cotg-dashboard-overtime-kpi .values-layer .row {
    display: flex;
    align-items: center;
}

.cotg-dashboard-overtime-kpi .values-layer .row:not(:last-child) {
    margin-bottom: 2px;
}


.cotg-dashboard-overtime-kpi .values-layer .row .circle {
    width: 5px;
    height: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    background-color: #e2d6fb;
    margin-right: 3px;
}

.cotg-dashboard-overtime-kpi .values-layer .row .value {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #ffffff;
}
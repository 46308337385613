.cotg-menu-overlay {
    position: fixed;
    top: -200px;
    left: -200px;
    bottom: -200px;
    right: -200px;
    z-index: 1;
}

.cotg-menu {
    position: absolute;
    left: 0;
    width: 163px;
    z-index: 2;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.34), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: #333d4f;
    border-radius: 5px;
}

.cotg-menu>.items {
    overflow-y: auto;
    border-radius: 5px;
}

.cotg-menu>.items::-webkit-scrollbar {
    width: 0;
}

.cotg-menu.search>.items {
    border-radius: 0 0 5px 5px;
}

.cotg-menu>.search {
    background: #333d4f;
    height: 48px;
    border-radius: 5px;
    display: flex;
    padding: 0 16px;
}

.cotg-menu>.search input {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
}

.cotg-menu>.search input::placeholder {
    color: #475266;
}

.cotg-menu>.search img {
    margin-left: 16px;
}

.cotg-menu>.items>.item {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    padding: 0 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.cotg-menu>.items>.item:active, .cotg-device-desktop .cotg-menu>.item:hover {
    background-color: #7A8599;
    cursor: pointer;
    user-select: none;
}
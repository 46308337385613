.cotg-user-lack-access {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.cotg-user-lack-access .title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.86px;
    margin-top: 27px;
}

.cotg-user-lack-access .description {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
}